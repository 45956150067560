/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addVehiclesCategory({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("vehicles/AddNewVehiclesCategorie", item)
        .then(response => {
          commit("ADD_VehiclesCategory", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllVehiclesCategories({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.post("/vehicles/GetAllVehiclesCategrios")
        .then((response) => {
          if (response.status == 200) {
            commit("SET_VehiclesCategory", response.data);
            resolve(response);
          }
        })
        .catch((error) => { reject(error) })
    })
  },

  GetVehiclesCategy(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("vehicles/GetVehiclesCategyById?ID="+ itemid)
        .then((response) => {
          debugger

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/UpdateVehiclesCategory" , item)
        .then(response => {
          debugger
          commit("UPDATE_VehiclesCategory", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

    DeleteVehiclesCategy({ commit }, item) {
      debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("vehicles/DeleteVehiclesCategory" , item)
        .then(response => {
          resolve(response);
          commit("REMOVE_VehiclesCategory", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
